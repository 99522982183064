import React, { useState } from 'react'
import Paragraphs from '../Paragraphs'
import PropTypes from 'prop-types'

const CookiesNotEnabledWarning = ({ lang }) => {
  const [showMoreInfo, setShowMoreInfo] = useState(false)
  return (
    <div className={'tw-fixed tw-w-full tw-text-white tw-z-50'}>
      <div
        className={'tw-m-auto tw-w-full tw-font-bold tw-bg-red-600 tw-flex tw-m-auto tw-py-2 tw-px-4 tw-animate-pulse tw-justify-center tw-gap-2 tw-items-center'}>
        <Paragraphs size={'base'}>
          {lang === 'es' ? 'Este navegador no tiene las cookies habilitadas. Habitalas para poder acceder a todas las funcionalidades de la aplicación.'
            : 'Aquest navegador no té les galetes habilitades. Habilita-les per poder accedir a totes les funcionalitats de l\'aplicació.'
          }
        </Paragraphs>
        <div className={'tw-cursor-pointer'} onClick={() => setShowMoreInfo(current => !current)}>
          <Paragraphs size={'base'} className={'tw-underline'}>
            +info
          </Paragraphs>
        </div>
      </div>
      {
        showMoreInfo &&
        <div
          className={'tw-mt-0.5 tw-max-w-[600px] tw-shadow-2xl tw-px-4 tw-mx-1.5 sm:tw-mx-auto tw-py-3 tw-text-black tw-m-auto tw-border-gray-400 tw-rounded tw-bg-blue-100 tw-opacity-100 tw-border-2 tw-left-1/3 tw-flex tw-flex-col tw-m-auto tw-justify-center tw-gap-2 tw-items-center'}>
          <Paragraphs size={'base'}>
            {lang === 'es' ? 'Habilitar cookies en tu navegador web es un proceso sencillo. Sigue estos pasos en tu navegador:' : 'Habilitar galetes en el teu navegador web és un procés senzill. Segueix aquests passos en el teu navegador:'}
            <ul className={'tw-list-disc tw-pl-10 tw-pt-2'}>
              <li>{lang === 'es' ? 'Busca el menú de Configuración o Ajustes (generalmente se encuentra en la esquina superior derecha de la pantalla).'
                : 'Busca el menú de Configuració o Ajustos (generalment es troba a la cantonada superior dreta de la pantalla).'}
              </li>
              <li>{lang === 'es' ? 'Busca la opción de Privacidad o Seguridad.' : 'Cerca l\'opció de Privadesa o Seguretat.'}</li>
              <li>{lang === 'es' ? 'Busca la opción de Cookies y haz clic en ella.' : 'Cerca l\'opció de Cookies i fes-hi clic.'}</li>
              <li>{lang === 'es' ? 'Habilita las cookies seleccionando la opción "Permitir" o "Aceptar"; cookies.' : 'Habilita les galetes seleccionant l\'opció "Permet" o "Acceptar" galetes.'}</li>
            </ul>
          </Paragraphs>
          <div
            className={
              'tw-cursor-pointer tw-mt-1 tw-shadow-gray-black tw-shadow tw-flex tw-justify-between tw-inline-block tw-px-4 tw-py-1 tw-bg-[#1867C0] tw-text-white hover:tw-opacity-[95%] active:tw-opacity-70 tw-rounded-full tw-text-xs'
            }
            onClick={() => setShowMoreInfo(current => !current)}
          >
            <Paragraphs
              className={'tw-tracking-widest tw-scale-x-[0.90]'}
            >
              {lang === 'es' ? 'Cerrar' : 'Tanca'}
            </Paragraphs>
          </div>
        </div>
      }
    </div>
  )
}

CookiesNotEnabledWarning.propTypes = {
  lang: PropTypes.string.isRequired
}

export default CookiesNotEnabledWarning
