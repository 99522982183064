import './App.css'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import LoginScreen from './components/LoginScreen'
import Logout from './components/Logout'
//
function App () {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<LoginScreen />} />
        <Route path={'/logout'} element={<Logout />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
