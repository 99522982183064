import React from 'react'
import PropTypes from 'prop-types'
import { Text } from './styles'

/**
 * @author ehernandez
 * @param {Object} properties properties for add
 * @returns {React.Component}
 */
const Paragraphs = ({
  children,
  italic,
  size,
  color,
  uppercase,
  family,
  className,
  weight,
  cy,
  display
}) => (
  <Text
    data-cy={cy}
    color={color}
    weight={weight}
    className={`Paragraphs tw-text-${size} tw-${display} ${italic ? 'tw-italic' : ''} ${uppercase && 'tw-uppercase'
      }  ${className}`}
    family={family}
  >
    {children}
  </Text>
)
Paragraphs.propTypes = {
  family: PropTypes.oneOf(['atkinson', 'gotham', 'Atkinson Hyperlegible', 'Roboto,serif']),
  weight: PropTypes.oneOf(['light', 'medium', 'bold', 'book', 'regular']),
  italic: PropTypes.bool,
  size: PropTypes.oneOf([
    'xxxs',
    'xxs',
    'xs',
    'sm',
    'base',
    'lg',
    'xl',
    '2xl',
    '3xl',
    '4xl',
    '5xl',
    '6xl',
    '7xl',
    '8xl',
    '9xl'
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  uppercase: PropTypes.bool,
  color: PropTypes.string,
  cy: PropTypes.string,
  display: PropTypes.oneOf(['block', 'inline-block', 'inline', 'flex'])
}
Paragraphs.defaultProps = {
  family: 'Roboto,serif',
  className: '',
  weight: 'regular',
  size: 'sm',
  children: ''
}
export default Paragraphs
